// Default theme copied from https://github.com/ng-select/ng-select/blob/master/src/ng-select/themes/default.theme.scss
@mixin rtl {
  @at-root [dir="rtl"] #{&} {
    @content;
  }
}

$ng-select-highlight: rgb(var(--color-primary-700)) !default;
$ng-select-primary-text: rgb(var(--color-text-main)) !default;
$ng-select-disabled-text: rgb(var(--color-secondary-100)) !default;
$ng-select-border: rgb(var(--color-secondary-500)) !default;
$ng-select-border-radius: 4px !default;
$ng-select-bg: rgb(var(--color-background-alt)) !default;
$ng-select-selected: transparent !default;
$ng-select-selected-alt: rgb(var(--color-text-main) / 0.06) !default;
$ng-select-selected-text: $ng-select-primary-text !default;

$ng-select-marked: rgb(var(--color-text-main) / 0.12) !default;
$ng-select-marked-text: $ng-select-primary-text !default;

$ng-select-box-shadow: none !default;
$ng-select-placeholder: rgb(var(--color-text-muted)) !default;
$ng-select-height: 35px !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;
$ng-select-value-text: $ng-select-primary-text !default;

$ng-select-dropdown-bg: $ng-select-bg !default;
$ng-select-dropdown-border: $ng-select-border !default;
$ng-select-dropdown-optgroup-text: rgb(var(--color-text-muted)) !default;
$ng-select-dropdown-optgroup-marked: $ng-select-dropdown-optgroup-text !default;
$ng-select-dropdown-option-bg: $ng-select-dropdown-bg !default;
$ng-select-dropdown-option-text: $ng-select-primary-text !default;
$ng-select-dropdown-option-disabled: rgb(var(--color-text-muted) / 0.6) !default;

$ng-select-input-text: $ng-select-primary-text !default;

// Custom color variables
$ng-select-arrow-hover: rgb(var(--color-secondary-700)) !default;
$ng-clear-icon-hover: rgb(var(--color-text-main)) !default;
$ng-dropdown-shadow: rgb(var(--color-secondary-100)) !default;

.ng-select {
  &.ng-select-opened {
    > .ng-select-container {
      background: $ng-select-bg;
      border-color: $ng-select-border;
      &:hover {
        box-shadow: none;
      }
      .ng-arrow {
        top: -2px;
        border-color: transparent transparent $ng-select-arrow-hover;
        border-width: 0 5px 5px;
        &:hover {
          border-color: transparent transparent $ng-select-arrow-hover;
        }
      }
    }
    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
    &.ng-select-right {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &.ng-select-left {
      > .ng-select-container {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $ng-select-highlight;
      box-shadow: $ng-select-box-shadow;
    }
  }
  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $ng-select-disabled-text;
    }
  }
  .ng-has-value .ng-placeholder {
    display: none;
  }
  .ng-select-container {
    color: $ng-select-primary-text;
    background-color: $ng-select-bg;
    border-radius: $ng-select-border-radius;
    border: 1px solid $ng-select-border;
    min-height: $ng-select-height;
    align-items: center;
    &:hover {
      box-shadow: 0 1px 0 $ng-dropdown-shadow;
    }
    .ng-value-container {
      align-items: center;
      padding-left: $ng-select-value-padding-left;
      @include rtl {
        padding-right: $ng-select-value-padding-left;
        padding-left: 0;
      }
      .ng-placeholder {
        color: $ng-select-placeholder;
      }

      .ng-input {
        > input {
          color: $ng-select-input-text;
        }
      }
    }
  }
  &.ng-select-single {
    .ng-select-container {
      height: $ng-select-height;
      .ng-value-container {
        .ng-input {
          top: 5px;
          left: 0;
          padding-left: $ng-select-value-padding-left;
          padding-right: 50px;
          @include rtl {
            padding-right: $ng-select-value-padding-left;
            padding-left: 50px;
          }
        }
      }
    }
  }
  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-text;
        border: 0px solid $ng-select-border; // Removing border on slected value when disabled
        .ng-value-label {
          padding: 0 5px;
        }
      }
    }
    .ng-select-container {
      .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;
        @include rtl {
          padding-right: 7px;
          padding-left: 0;
        }
        .ng-value {
          font-size: $ng-select-value-font-size;
          margin-bottom: 5px;
          color: $ng-select-value-text;
          background-color: $ng-select-selected;
          border-radius: 2px;
          margin-right: 5px;
          @include rtl {
            margin-right: 0;
            margin-left: 5px;
          }
          &.ng-value-disabled {
            background-color: $ng-select-disabled-text;
            .ng-value-label {
              padding-left: 5px;
              @include rtl {
                padding-left: 0;
                padding-right: 5px;
              }
            }
          }
          .ng-value-label {
            display: inline-block;
            padding: 1px 5px;
          }
          .ng-value-icon {
            display: inline-block;
            padding: 1px 5px;
            &:hover {
              background-color: $ng-select-arrow-hover;
            }
            &.left {
              border-right: 1px solid $ng-select-selected;
              @include rtl {
                border-left: 1px solid $ng-select-selected;
                border-right: none;
              }
            }
            &.right {
              border-left: 1px solid $ng-select-selected;
              @include rtl {
                border-left: 0;
                border-right: 1px solid $ng-select-selected;
              }
            }
          }
        }
        .ng-input {
          padding: 0 0 3px 3px;
          @include rtl {
            padding: 0 3px 3px 0;
          }
        }
        .ng-placeholder {
          top: 5px;
          padding-bottom: 5px;
          padding-left: 3px;
          @include rtl {
            padding-right: 3px;
            padding-left: 0;
          }
        }
      }
    }
  }
  .ng-clear-wrapper {
    color: $ng-select-placeholder;
    padding-top: 2.5px;
    &:hover .ng-clear {
      color: $ng-clear-icon-hover;
    }
  }
  .ng-spinner-zone {
    padding: 5px 5px 0 0;

    @include rtl {
      padding: 5px 0 0 5px;
    }
  }
  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;
    @include rtl {
      padding-left: 5px;
      padding-right: 0;
    }
    &:hover {
      .ng-arrow {
        border-top-color: $ng-select-arrow-hover;
      }
    }
    .ng-arrow {
      border-color: $ng-select-placeholder transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
    }
  }
}

.ng-dropdown-panel {
  background-color: $ng-select-dropdown-bg;
  border: 1px solid $ng-select-dropdown-border;
  box-shadow: 0 1px 0 $ng-dropdown-shadow;
  left: 0;
  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: $ng-select-border-radius;
    border-top-left-radius: $ng-select-border-radius;
    border-bottom-color: $ng-select-border;
    margin-bottom: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: $ng-select-border-radius;
          border-top-left-radius: $ng-select-border-radius;
        }
      }
    }
  }
  &.ng-select-right {
    left: 100%;
    top: 0;
    border-top-right-radius: $ng-select-border-radius;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-bottom-color: $ng-select-border;
    margin-bottom: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: $ng-select-border-radius;
        }
      }
    }
  }
  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-top-color: $ng-select-border;
    margin-top: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: $ng-select-border-radius;
          border-bottom-left-radius: $ng-select-border-radius;
        }
      }
    }
  }
  &.ng-select-left {
    left: -100%;
    top: 0;
    border-top-left-radius: $ng-select-border-radius;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-bottom-color: $ng-select-border;
    margin-bottom: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-left-radius: $ng-select-border-radius;
        }
      }
    }
  }
  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 500;
      color: $ng-select-dropdown-optgroup-text;
      cursor: pointer;
      &.ng-option-disabled {
        cursor: default;
      }
      &.ng-option-marked {
        background-color: $ng-select-marked;
      }
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-dropdown-optgroup-marked;
        background-color: $ng-select-selected;
        font-weight: 600;
      }
    }
    .ng-option {
      background-color: $ng-select-dropdown-option-bg;
      color: $ng-select-dropdown-option-text;
      padding: 8px 10px;
      &.ng-option-selected {
        background-color: $ng-select-selected-alt;
      }
      &.ng-option-selected.ng-option-marked {
        background-color: $ng-select-marked;
      }
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-selected-text;
        .ng-option-label {
          font-weight: 600;
        }
      }
      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $ng-select-marked-text;
      }
      &.ng-option-disabled {
        color: $ng-select-dropdown-option-disabled;
      }
      &.ng-option-child {
        padding-left: 22px;
        @include rtl {
          padding-right: 22px;
          padding-left: 0;
        }
      }
      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
        @include rtl {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
  }

  @include rtl {
    direction: rtl;
    text-align: right;
  }
}
